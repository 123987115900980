
/* JS */

/* react */
import React from 'react';
/* third part components */

/* custom components */
import CaseStudyPage from './CaseStudyPage'; 

var gsap;
var detect = require('is-client');
if (detect()) {
	gsap = require('gsap');
	let sctr = require('gsap/ScrollTrigger');
	gsap.gsap.registerPlugin(sctr);
}

const MolendiniPage = props => {
	return (
		<CaseStudyPage link="molendini" title="Brand &amp; Branding, Comunicazione - Molendini"
		relPath="molendini" description="Molendini esprime una visione globale della ristorazione. Propone una vasta gamma di servizi, dalla cucina con menu, alla pizzeria napoletana, aperitivi, mixology e cocktail bar. E soprattutto una grande attenzione al dettaglio.">
		</CaseStudyPage>
	);
};

export default MolendiniPage;
